import { Dialog, DialogPanel, DialogTitle, Transition } from "@headlessui/react"
import classNames from "classnames"
import { PartyPopperIcon } from "lucide-react"
import React, { Fragment } from "react"
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage"
import { ButtonSecondary } from "../../UI/Button/Button"
import {
  connector,
  ContainerProps,
} from "./containers/search-gpt-keywords-image-response-modal.containers"

export const Wrapper: React.FC<ContainerProps> = (props) => {
  const isOpen = props.image !== null

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          onClose={props.onClose}
          className={`fixed inset-0 z-40 flex min-w-fit items-end justify-center p-4 sm:items-center`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-900 bg-opacity-90 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 sm:scale-90"
            enterTo="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 sm:scale-100"
            leaveTo="opacity-0 sm:scale-90"
          >
            <DialogPanel
              className={classNames(
                "relative z-10 mx-auto flex max-h-full flex-col overflow-auto rounded-md border border-slate-100 bg-white"
              )}
            >
              <div className=" p-4 md:h-auto md:p-8 md:py-8">
                <div className="relative w-full max-w-md">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-emerald-100">
                    <PartyPopperIcon
                      className="h-5 w-5 text-emerald-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 sm:mt-6">
                    <DialogTitle
                      as="h3"
                      className=" text-base font-semibold leading-6 "
                    >
                      L'image de la réponse de ChatGPT
                    </DialogTitle>

                    <p className="text-sm text-slate-500">
                      Cette image est générée par ChatGPT et représente les
                      mots-clés sélectionnés.
                    </p>

                    <img
                      src={`data:image/png;base64,${props.image}`}
                      alt="ChatGPT response"
                      className="border border-slate-100 rounded-md"
                    />
                  </div>

                  <div className="mt-8 flex items-center justify-between space-x-2">
                    <ButtonSecondary size="sm" onClick={props.onClose}>
                      <FormattedMessage id="indexation/auto-settings-modal/close" />
                    </ButtonSecondary>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export const SearchGptKeywordsImageResponseModal = connector(Wrapper)
